import React from 'react'
import { Link } from 'react-router-dom'

const SneakPeek = ({ media, loaded }) => {
    if (media === null || !loaded) return <div>Loading . . .</div>
    const images = media.filter(element => element.media_type !== 'VIDEO')

    return (
        <div>
            <div className='contactTitle checkGallery'>Check out our</div>
            <div className='contactTitle'>
                <Link to={'/gallery'} className='clickGallery'>Gallery</Link>
            </div>
            { <div className='frontPictures'>
                <div className='image-grid image-grid2'>
                    <img className='image imageSide' src={images[28].media_url} />
                    <img className='image image2' src={images[51].media_url} />
                    <img className='image image2' src={images[24].media_url} />
                    <img className='image imageSide1' src={images[0].media_url} />
                </div>
                <div className='image-grid image-grid3'>
                    <img className='image image2' src={images[15].media_url} />
                    <img className='image image2' src={images[27].media_url} />
                    <img className='image image2' src={images[33].media_url} />
                    <img className='image image2' src={images[11].media_url} />
                </div>
                <div className='image-grid image-grid4'>
                    <img className='image imageSide2' src={images[50].media_url} />
                    <img className='image image2' src={images[20].media_url} />
                    <img className='image image2' src={images[21].media_url} />
                    <img className='image imageSide3' src={images[18].media_url} />
                </div>
            </div> }
        </div>
    )
}

export default SneakPeek