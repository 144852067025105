import React from 'react'
import { Link } from 'react-router-dom'

const About = ({ media, loaded }) => {
    return (media === null || !loaded) ? 
    <div className='about' id='home' >
    <div>
        Octopussy Tattoos is a unique tattoo studio located in the heart of Helsinki.
        The Studio was established 2016 in Lauttasaari and the current shop has been open since September 2020.
        Our Tattoo Artists are Zsofia Balogh from Transylvania and Sakoro Sako from Rio de Janeiro.
        <br />
        <br />
        Our Helsinginkatu studio was chosen because of its practical location -
        the small stone leg street shop with cozy decoration creates an intimate atmosphere and allows
        clients to relax in a total privacy. Octopussy Tattoos Studio is a fully certified sterile and
        hygienic environment. Our Studio is open by appointments. Age limit for tattoos are 18 years and up.
        <br />
        <br />
        We&apos;re specialized in custom-made unique tattoo designs, both black and colour.
        We do tattoo styles like dotwork, geometry, mandala, black & grey, blackwork, fine line, Maori &
        Polynesian and traditional/old school tattooing as well as Oriental and Lettering.
    </div>
    </div >
    :
     (
        <div className='about' id='home' >
            <div>
                Octopussy Tattoos is a unique tattoo studio located in the heart of Helsinki.
                The Studio was established 2016 in Lauttasaari and the current shop has been open since September 2020.
                Our Tattoo Artists are Zsofia Balogh from Transylvania and Sakoro Sako from Rio de Janeiro.
                <br />
                <div>
            <div className='contactTitle checkGallery'>Take a look at our</div>
            <div className='contactTitle'>
                <Link to={'/studio'} className='clickGallery'>Studio</Link>
            </div>
            <img className='image' src={media[0].media_url} />

        </div>
                <br />
                Our Helsinginkatu studio was chosen because of its practical location -
                the small stone leg street shop with cozy decoration creates an intimate atmosphere and allows
                clients to relax in a total privacy. Octopussy Tattoos Studio is a fully certified sterile and
                hygienic environment. Our Studio is open by appointments. Age limit for tattoos are 18 years and up.
                <br />
                <br />
                We&apos;re specialized in custom-made unique tattoo designs, both black and colour.
                We do tattoo styles like dotwork, geometry, mandala, black & grey, blackwork, fine line, Maori &
                Polynesian and traditional/old school tattooing as well as Oriental and Lettering.
            </div>
        </div >
    )
}

export default About