import React, { useEffect, useState, useRef } from 'react'
//import userService from './services/user'
//import mediaService from './services/media'
import useOnClickOutside from './hooks/OutsideClick'

//import Entry from './components/Entry'
import AppBar from './components/AppBar'
import Header from './components/Header'
import About from './components/About'
import Contact from './components/Contact'
import Footer from './components/Footer'
import Info from './components/Info'
import Gallery from './components/Gallery'
import BurgerMenu from './components/BurgerMenu'
import Burger from './components/Burger'
import SneakPeek from './components/SneakPeek'
import Scroll from './components/Scroll'
import Studio from './components/Studio'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'

const App = () => {
    const [allMedia, setAllMedia] = useState(null)
    const [studioMedia, setStudioMedia] = useState(null)
    //const [isPlayed, setIsPlayed] = useState(false)
    const [loaded, setIsLoaded] = useState(false)
    const [open, setOpen] = useState(false)
    const [isClicked, setClicked] = useState(false)
    const node = useRef()
    //const userID = '17841402105232117'
    //const accessToken = 'IGQWRNYUIySHN6ZAGtiOEJvOE9VRzJrb2R2YzNHOW1SX3RtSTEzZA3ctcU9aNFY2STdDUDQteWZAHVnRWR012M21VbmxBTHgwYzJtUWdnd2ktdzQwOURiOGRoWnp4UXlfRVU2ZA2JTdV9od0d2V2t2SG9TUFhncHBHalEZD'

    /**
     * Local image loading to combat deprecated shitty Instagram Basic Display API.
     */
    useEffect(() => {
        setAllMedia(
            formatLocallyImportedMedia(
                importAllLocalMedia(require.context('./assets/images/art', false, /\.(png|jpe?g|svg)$/))
            )
        )
        setStudioMedia(
            formatLocallyImportedMedia(
                importAllLocalMedia(require.context('./assets/images/studio', false, /\.(png|jpe?g|svg)$/))
            )
        )
        setIsLoaded(true)
    }, [0])

    /**
     * Deprecated shitty Instagram Basic Display API
     */
    // useEffect(() => {
    //     mediaService
    //         .getAllMedia(userID, accessToken)
    //         .then(allMedia => {
    //             setAllMedia(allMedia.data)
    //             setIsLoaded(true)
    //  })
    // userService
    //     .refreshLongToken(accessToken)
    // .then(node => {
    //     console.log(node.expires_in)
    // })
    // userService.getUserNode(userID, accessToken).then(response => console.log(response))
    //}, [])

    useEffect(() => {
        const body = document.getElementById('contact')
        if (body) {
            body.scrollIntoView({
                behavior: 'smooth'
            }, 500)
        }
    }, [isClicked])

    /**
     * Watcher function for user clicks.
     */
    useOnClickOutside(node, () => setOpen(false))

    /**
     * Function to recursively map locally imported media.
     * @param {element} element
     * @returns Object containing all locally imported media files.
     */
    const importAllLocalMedia = (element) => {
        return element.keys().map(element)
    }

    const formatLocallyImportedMedia = (mediaToFormat) => {
        const formattedMedia = mediaToFormat.map((element, idx) =>  (
            {
                id: idx,
                media_url: element.default,
                title: 'Octopussy Tattoos',
                type: ''
            }
        )
        )
        return formattedMedia
    }

    console.log(studioMedia)

    return (
        <>
            <Router>
                {/* <Scroll id='entry' >
                    <Entry isPlayed={isPlayed} setIsPlayed={setIsPlayed} />
                </Scroll> */}
                <div ref={node}>
                    <BurgerMenu open={open} setOpen={setOpen} isClicked={isClicked} setClicked={setClicked} />
                    <Burger open={open} setOpen={setOpen} />
                </div>
                <AppBar isClicked={isClicked} setClicked={setClicked} />
                <Header />
                <Switch>
                    <Route exact path='/info'>
                        <Scroll id='languageButton'>
                            <Info />
                        </Scroll>
                    </Route>
                    <Route exact path='/gallery'>
                        <Scroll id='grid-scroll'>
                            <Gallery media={allMedia} loaded={loaded} />
                        </Scroll>
                    </Route>
                    <Route exact path='/studio'>
                        <Scroll id='grid-scroll'>
                            <Studio media={studioMedia} loaded={loaded} />
                        </Scroll>
                    </Route>

                    <Route exact path='/'>
                        {/* {isPlayed ?
                            <Scroll id='home'>
                                <About />
                                <SneakPeek media={allMedia} />
                                <Contact />
                            </Scroll>
                            : <div>
                                <About />
                                <SneakPeek media={allMedia} />
                                <Contact />
                            </div>} */}
                        <About media={studioMedia} loaded={loaded} />
                        <SneakPeek media={allMedia} loaded={loaded} />
                        <Contact />
                    </Route>
                </Switch>
            </Router>
            <Footer />
        </>
    )
}

export default App